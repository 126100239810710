<template>
    <ContactForm v-editable="blok" :data="formContent" />
</template>

<script setup lang="ts">
import ContactFormType from '@/types/ContactFormType';
import ContactForm from '~/components/page-building/contact-form/ContactForm.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const formContent = computed<ContactFormType>(() => {
    return {
        title: props.blok.title,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
    };
});
</script>
