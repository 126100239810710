<template>
    <BlockContainer
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="data.nextBackgroundColor"
        slot-class="lg:flex items-center my-20 lg:my-32"
    >
        <div
            class="py-8 lg:order-2 lg:-ml-[1px] lg:w-1/2 lg:border-l lg:border-grind lg:pl-12 xl:pl-20"
        >
            <h3 class="mb-6 text-center">{{ data.title }}</h3>

            <ClientOnly>
                <Alert v-if="success" type="success" :dismissable="false">
                    Thank you for sending us a message.
                </Alert>

                <ValidatedForm
                    v-else
                    ref="formRef"
                    :form-errors="formErrors"
                    :show-required-fields-message="true"
                    @submit="validateAndSubmit"
                >
                    <TextInput
                        v-model="name"
                        name="name"
                        type="text"
                        label="Name *"
                        :submitting="submitting"
                        :form-errors="formErrors"
                        container-class="w-full mb-4"
                        input-class="floating-label-white"
                        required
                    />

                    <div class="gap-4 lg:flex lg:flex-row">
                        <TextInput
                            v-model="email"
                            name="email"
                            type="text"
                            label="Email Address *"
                            :submitting="submitting"
                            :form-errors="formErrors"
                            container-class="lg:w-1/2 mb-4"
                            input-class="floating-label-white"
                            required
                        />

                        <TextInput
                            v-model="phone"
                            name="phone"
                            type="tel"
                            label="Phone Number *"
                            :submitting="submitting"
                            :form-errors="formErrors"
                            container-class="lg:w-1/2 mb-4"
                            input-class="floating-label-white"
                            required
                        />
                    </div>

                    <TextareaInput
                        v-model="message"
                        name="message"
                        label="Message *"
                        :submitting="submitting"
                        :form-errors="formErrors"
                        container-class="w-full text"
                        input-class="floating-label-white"
                        required
                    />

                    <div
                        v-if="recaptchaEnabled"
                        class="g-recaptcha mt-4"
                        :data-sitekey="recaptchaSiteKey"
                    ></div>

                    <LoadingButton
                        v-if="!success"
                        type="submit"
                        class="btn-primary mt-10 w-full"
                        :loading="submitting"
                    >
                        Submit
                    </LoadingButton>
                </ValidatedForm>
            </ClientOnly>
        </div>

        <div
            class="mt-12 flex-1 py-8 lg:order-1 lg:mt-0 lg:border-r lg:border-grind lg:pr-12 xl:pr-20"
        >
            <div class="text-center" data-cy="product-finder-cta">
                <h3>Need help finding a product? Use our product finder.</h3>

                <div
                    class="mb-10 mt-16 flex justify-center pl-12 lg:mb-12 lg:mt-20 xl:mb-14 xl:mt-28"
                >
                    <div
                        class="relative max-w-[240px] lg:max-w-[300px] xl:max-w-[360px]"
                    >
                        <img
                            src="~/assets/images/contact-us/contact-us-sterling-decorations.svg"
                            alt="Meet Sterling! He’s here to help guide you to the right product."
                            class="absolute -left-[26%] -top-[11%] w-[60%]"
                        />
                        <img
                            src="~/assets/images/contact-us/contact-us-sterling.webp"
                            alt="Sterling"
                            class="w-full"
                        />
                    </div>
                </div>

                <NuxtLink class="btn btn-secondary w-full" to="/product-finder">
                    Get Started
                </NuxtLink>
            </div>
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import ContactFormType from '~/types/ContactFormType';
import BlockContainer from '~/components/BlockContainer.vue';
import TextInput from '~/components/forms/TextInput.vue';
import TextareaInput from '~/components/forms/TextareaInput.vue';
import ValidatedForm from '~/components/forms/ValidatedForm.vue';
import { useSegmentEvent } from '~/composables/useSegment';

defineProps<{
    data: ContactFormType;
}>();

const { recaptchaEnabled, recaptchaSiteKey } = useRuntimeConfig().public;

const formRef = ref<InstanceType<typeof ValidatedForm> | null>(null);

const state = reactive({
    name: '',
    email: '',
    phone: '',
    message: '',
    submitting: false,
    success: false,
    formErrors: {},
});

const { name, email, phone, message, submitting, success, formErrors } =
    toRefs(state);

const validateAndSubmit = async () => {
    if (formRef.value?.validate()) {
        state.submitting = true;

        let recaptchaToken = '';
        try {
            recaptchaToken = window.grecaptcha.getResponse();
        } catch {}

        await useAuth()
            .apiRequest('POST', 'contact-us-ajax-recaptcha', {
                name: state.name,
                email: state.email,
                phone: state.phone,
                message: state.message,
                page: 'contact-us',
                'g-recaptcha-response': recaptchaToken,
            })
            .then((response) => {
                state.formErrors = {};
                state.success = true;
                state.name = '';
                state.email = '';
                state.phone = '';
                state.message = '';

                const segmentEvent = useSegmentEvent(
                    'Contact Us Form Submit',
                    'Contact Us',
                    'Contact Us Form Submit',
                );

                useHead(() => ({
                    script: segmentEvent,
                }));
            })
            .catch((error) => {
                state.formErrors = getFormErrors(error.data);
            })
            .finally(() => {
                state.submitting = false;
                if (recaptchaEnabled && window.grecaptcha) {
                    window.grecaptcha.reset();
                }
            });
    }
};

onMounted(() => {
    useHead({
        script: [
            {
                src: 'https://www.google.com/recaptcha/api.js',
                async: true,
                defer: true,
            },
        ],
    });
});
</script>
